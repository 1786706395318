<template>
  <div style="margin:0 auto;max-width:640px;width:100%">
    <!-- <div class="header center">{{ orderinfo.xname }}姓宝宝起名结果</div> -->
    <div class="nav">
      <div class="navitem "  @click="gotoresult" >起名结果</div>
      <div class="navitem active">高分好名</div>
      <div class="navitem" @click="gototeacher">人工专家起名</div>
    </div>
    <img src="//cdn.zhiyileiju.cn/WechatIMG77.jpeg" style="width:100%;min-height:80px">
    <div class="buybox">
        <div class="buytip">50套甄选高分好名</div>
        <div class="buytipdesc">好名均由专家提前精选</div>
        <div class="pricebox">
            <div class="priceitem">
              <span style="font-size:11px;color:#979797;text-decoration: line-through;margin-bpttom:8px">原价：￥198</span>
              <span style="font-size:30px;color:#ff0000;font-weight:bold">￥{{price.plus_amount}}</span>
            </div>
            <div class="timebox">
              <span style="font-size:12px;color:#323232;margin-bottom:8px">限时优惠结束</span>
              <div>
                <van-count-down :time="counttime" millisecond>
                  <template #default="timeData">
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
        </div>
        <van-button type="primary" color="#04C967" class="paybtn" icon="//cdn.zhiyileiju.cn/wechaticon111.png" round block @click="paynow">微信支付</van-button>
        <div class="getnum center" style="margin-top:10px">
					已有<span style="color:#ff0000">3,123,818</span>个人支付，获取了50套好名
				</div>
				<img src="//cdn.zhiyileiju.cn/baozhang.png" class="center" style="width: 80%;margin:0 auto; margin-top: 15px;">
			
    </div>
    <div class="descbox">
      <div class="desctit">支付后可再获取50个双字名</div>
      <ul class="descitem">
        <li>生辰八字分析</li>
        <li>专家团队精选</li>
        <li>最佳名字组合</li>
      </ul>
      <p class="tebie">特别说明: 50套高分好名（双字名）, 是根据命主八字与性格分析，在专家团队精选的名字中，挑选50套符合命主气质、数理、音译、寓意中最优的名字，是“十全大补”的吉名！</p>
    </div>

    <!-- 红包弹窗 -->
		<van-popup class="redpacket" v-model="showredPacket">
			<div class="redpacketbox">
				<div class="center redtitile">恭喜获得红包！</div>
				<div class="center dicsountnum" style="line-height:1em;align-items: flex-end;font-size:40px;color:red;font-weight:500;margin-top:25px">
					￥8.00
					<span style="margin-left:5px;line-height:1.5em;font-size:18px">元</span>
				</div>
				<div class="center" style="margin-top:15px;font-size:14px;color:#C37D3F">使用可减价查看50个甄选好名</div>
				<div class="countbox center">
					<van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
						</template>	
					</van-count-down>
					<span style="color:#fff;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
				</div>
				<van-button class="redbtn" @click="buy" round>点击立即使用</van-button>
			</div>	
		</van-popup>
  </div>
</template>

<script>

import axios from "../utils/request";
import router from "../router";

import {  CountDown,Popup } from "vant";
import report from "../utils/stat";

export default {
  name: "result",
  components: {
    vanCountDown: CountDown,
    vanPopup:Popup
  },
  async mounted() {

  },
  computed: {
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      looktype: 0,
      counttime: 10000 * 60,
      price:{},
      showredPacket:false
    };
  },
  async beforeCreate() {
    //如果订单未支付，直接跳回首页
    let data = await axios.get("/order/" + this.$route.params.id);
    let orderinfo = data.data.data;
    let price = await axios.get(`/getSalesPrice?fromk=${orderinfo.fromk}`);
    this.price = price.data.data[0];
  },
  async created() {
    this.fromk = this.$route.query.from;
    report("v1_buyplusname");
    setTimeout(function(){
        window.scrollTo(0, 0)
    },100)
  },
  async mounted() {
    if (this.is_weixn) {
      let wxconfig = await this.getWxConfig();
      wx.config({
        debug: false,
        appId: wxconfig.appId,
        timestamp: wxconfig.timestamp,
        nonceStr: wxconfig.nonceStr,
        signature: wxconfig.signature,
        jsApiList: ["chooseWXPay"],
      });
    }
  },
  methods: {
    gototeacher() {
      router
        .push({
          name: "teacher",
          params: {
            id: this.$route.params.id,
          },
        })
        .catch((err) => {});
    },
    async buy(){
      let orderinfo;
      report("v1_redpacket_buyplus");
      if (this.is_weixn) {
        try {
          orderinfo = await axios.post("/order/confirm", {
            order_id: this.$route.params.id,
            from_type: 1,
            from_order_id: this.$route.params.id,
            paytype: 2,
            discount_id: 1,
          });
        } catch (err) {
          alert("下单失败");
        }
        let payinfo = await axios.get(
          `https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}/wechat`
        );
        let pay = payinfo.data.data;
        wx.ready(function() {
          wx.chooseWXPay({
            timestamp: pay.timeStamp,
            nonceStr: pay.nonceStr,
            package: pay.package,
            signType: pay.signType,
            paySign: pay.paySign, // 支付签名
            success: function(res) {
              router
                .push({
                  name: "plusname",
                  params:{
                    resultid:this.$route.params.id,
                    id:orderinfo.data.data.id
                  }
                })
                .catch((err) => {});
            }
          });
        });
      } else {
        alert("请到微信内打开此页面下单购买");
      }
    },
    gotoresult(){
      router.push({
        name:"payresult",
        params:{
           id: this.$route.params.id,
        }
      }).catch(err => {})
    },
    async paynow() {
      let orderinfo;
      let t = this;
      report("v1_plusbuypage_buyplus");
      if (this.is_weixn) {
        try {
          orderinfo = await axios.post("/order/confirm", {
            order_id: this.$route.params.id,
            from_type: 1,
            from_order_id: this.$route.params.id,
            paytype: 2,
          });
        } catch (err) {
          alert("下单失败");
        }
        let payinfo = await axios.get(
          `https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}/wechat`
        );
        let pay = payinfo.data.data;
        wx.ready(function() {
          wx.chooseWXPay({
            timestamp: pay.timeStamp,
            nonceStr: pay.nonceStr,
            package: pay.package,
            signType: pay.signType,
            paySign: pay.paySign, // 支付签名
            success: function(res) {
              router
                .push({
                  name: "plusname",
                  params:{
                    resultid:this.$route.params.id,
                    id:orderinfo.data.data.id
                  }
                })
                .catch((err) => {});
            },
            fail:function(){
              t.showredPacket = true
            },
            cancel:function(){
               t.showredPacket = true
            }
          });
        });
      } else {
        alert("请到微信内打开此页面下单购买");
      }
    },
    async getWxConfig() {
      let url = window.location.href;
      let data = await axios.post("/payment/wechatconf", {
        url,
      });
      return data.data.data.data;
    }
  },
};
</script>

<style lang="less">

	.redpacket.van-popup {
		background: none;
	}

	.redpacketbox{
		width: 75vw;
		height: 108vw;
		max-width: 300px;
		max-height: 446px;
		padding-top: 70px;
		box-sizing: border-box;
		background: url('//cdn.zhiyileiju.cn/WechatIMG103.png') no-repeat center;
		background-size: 100%;
	}

	.redbtn{
		width: 55vw;
    max-width: 300px;
		height: 45px;
		display: block;
		margin: 0 auto;
		margin-top: 20px;
		background: #FEC995;
		border: none;
		color: #C41713;
		font-size: 18px;
		font-weight: 500;
	}

	.redtitile{
		color: #E38000;
		font-weight: bold;
		font-size: 18px;
	}

  
	.countbox{
		margin-top:80px;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #E51D2C;
      }

.nav {
  height: 35px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #b95353;
  border-top: 1px solid #b95353;
  margin-bottom: 2px;

  .navitem {
    width: 50%;
    height: 100%;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #b95353;
    background: #fff;
    border-right: 1px solid #b95353;
    &.active {
      background: #b95353;
      color: #fff;
    }
  }
}

.buybox{
  width:calc(100% - 15px);
  background: #fff;
  border-radius: 5px;
  margin: 0 auto;
  padding: 16px;
  box-sizing: border-box;
  .buytip{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .buytipdesc{
    font-size: 11px;
    color: #323232;
    font-weight: normal;
    text-align: center;
  }

  .pricebox{
    margin-top: 22px;
    display: flex;
    justify-content: space-between;

    .priceitem{
      display: flex;
      flex-direction: column;
  
    }

    .timebox{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      min-width: 140px;
      height: 50px;
      border-left: 1px solid #979797;
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #ff0000;
      }
      .block {
        display: inline-block;
        width: 15px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #ff0000;
      }
    }
  }

  .paybtn{
    margin-bottom: 14px;
    width:calc(100% - 10px);
    display: block;
    margin: 0 auto;
    font-size:22px;
    margin-top: 26px;
  }
}

.descbox{
    width:calc(100% - 15px);
    background: #fff;
    border-radius: 5px;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 40px;
  .desctit{
    text-align: center;
    color: #323232;
    font-size: 16px;
    font-weight: bold;
  }
  .descitem{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 14px;
    li{
      background: #FDF0D0;
      border-radius: 5px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      height: 30px;
      color: #B95353;
      font-size: 14px;
      &:nth-child(2){
        margin: 0 10px;
      }
    }
  }

  .tebie{
    padding: 0;
    font-size: 14px;
    margin: 0;
    color: #323232;
    line-height: 2em;
    margin-top: 18px;
  }
}


</style>
